import React, { useEffect, useRef } from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";

export const JetztBewerben = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    if (ref && ref.current) {
      script.src = "https://ifbck.com/api/widget/b62855d01b22a06b/widget.js";
      script.async = true;
      script.defer = true;
      script.type = "application/javascript";
      ref.current?.appendChild(script);
    }
    return () => {
      ref.current?.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <StyledContainer
        ref={(interalRef) => (ref.current = interalRef)}
        className="elementor-widget-container "
      ></StyledContainer>
    </Layout>
  );
};

const StyledContainer = styled("div", {
  width: "100%",
  padding: "32px 16px",
  margin: "0 auto",
  "@md": {
    maxWidth: 1280,
    padding: "64px 0px",
  },
  "#iFrameWrapper-b62855d01b22a06b": {
    width: "100%",
    maxWidth: "unset",
    maxHeight: "unset",
    boxShadow: "none !important",
    height: "1000px",
    margin: 0,
    iframe: {
      border: "none",
    },
  },

  ".elementor-widget-container": {
    width: "100%",
  },
});
